<template>
  <!-------------- 좌측 패널 -------------->
  <div class="panel" :style="afterIconMarginLeft">
    <div class="panel-top-sort">
      <span class="sort-img-search">Search</span>
      <slot name="searchOptions"></slot>
      <input
        type="button"
        :value="btnSearch"
        class="sort-btn-search"
        style="color: #ffffff;"
        prepend-icon="mdi-chevron-down"
        @click="search"
      />
    </div>
    <div class="panel-top-total">
      <!-------------- 토탈 -------------->
      <p class="table-total">
        <span class="tx-total "
          >Total:
          <b>{{ totalRecords }}</b>
        </span>
      </p>
      <!-------------- subHeaderItem -------------->
      <p class="table-btns" style="margin:0px; color:#FF6D01;">
        <slot name="subHeaderItem"></slot>
      </p>
    </div>
    <!-------------- 목록 -------------->
    <v-container fluid class="pa-0">
      <!-- <v-overlay absolute="absolute" :value="loading">
        <v-progress-circular
          indeterminate
          size="50"
          color="deep-orange lighten-2"
        ></v-progress-circular>
      </v-overlay> -->
      <v-data-table
        dense
        :fixed-header="true"
        :height="tableHeight"
        :headers="tableHeaders"
        :items="tableItems"
        :items-per-page="nowPerPage"
        :server-items-length="totalRecords"
        item-key="index"
        :single-select="singleSelect"
        :show-select="showSelect"
        checkbox-color="blue"
        v-model="selected"
        @input="enterSelect()"
        primary-key="index"
        class="elevation-1"
        :options.sync="options"
        :loading="loading"
        loading-text="Loading..."
        :footer-props="{
          showFirstLastPage: showFirstLastIcon,
          firstIcon: 'mdi-arrow-collapse-left',
          lastIcon: 'mdi-arrow-collapse-right',
          prevIcon: 'mdi-arrow-left',
          nextIcon: 'mdi-arrow-right',
          'items-per-page-options': pageOptions,
          'items-per-page-text': '',
        }"
        :mobile-breakpoint="0"
        @click:row="rowClick"
      >
        <template v-slot:[`item.index`]="{ item }">
          <td class="text-center table-index" :title="`${item.index}`">
            {{ item.index }}
          </td>
        </template>
        <!-- customSlot -->
        <template
          v-for="info in customSlotInfo"
          v-slot:[info.slotName]="{ item }"
        >
          <slot :name="`${info.name}_custom`" v-bind:item="item" />
        </template>
        <!-- no data -->
        <template v-slot:no-data>
          {{ $t('common.pagination.row-noData') }}
        </template>
        <!--progress bar-->
        <template v-slot:progress>
          <v-progress-linear
            :active="loading"
            :indeterminate="loading"
            absolute
            bottom
            color="deep-orange lighten-2"
          />
        </template>
        <!--overlay: overlay 사용할 경우, :loading="loading" 지워야함-->
        <!-- <template v-slot:[`body.append`]>
          <v-overlay absolute="absolute" :value="loading">
            <v-progress-circular
              indeterminate
              size="50"
              color="deep-orange lighten-2"
            ></v-progress-circular>
          </v-overlay>
        </template> -->
        <!-- custom footer -->
        <template v-slot:[`footer.page-text`]>
          {{ $t('common.pagination.label-page') }}
          <input
            :value="options.page"
            type="number"
            class="input-page"
            :max="totalPages"
            min="1"
            @keyup.enter="checkInput"
            :disabled="!showFirstLastIcon"
          />
          /
          {{ totalPages }}
        </template>
        <!-- startIdx-endIdx of totalRecords -->
        <template v-slot:[`footer`]="item">
          <div
            style="position: absolute; right: 0; height: 40px; bottom: 8px;"
            class="pa-5 pl-2"
            label="Show Archived"
          >
            {{
              item.props.pagination.itemsLength
                ? ` ${$t('common.pagination.label-view')} ${item.props
                    .pagination.pageStart + 1} - 
                ${item.props.pagination.pageStop}  /
            ${item.props.pagination.itemsLength}`
                : $t('common.pagination.label-view-noData')
            }}
          </div>
        </template>
      </v-data-table>
    </v-container>
  </div>
</template>
<script>
import mixins from '@/mixins/CommonMixins';

export default {
  mixins: [mixins],
  props: {
    headers: {
      type: Array,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    tableHeight: {
      type: String,
      required: true,
    },
    nowPerPage: {
      type: Number,
      required: true,
    },
    totalRecords: {
      type: Number,
      required: false,
    },
    searchOpts: {
      type: Object,
      required: false,
    },
    totalPages: {
      type: Number,
      required: false,
    },
    customSlotInfo: {
      type: Array,
      required: false,
    },
    // 등록 후, reload 해야할 경우 toggle 활용 (predictionPage.vue 예제)
    reloadToggle: {
      type: Boolean,
      required: false,
    },
    loading: {
      type: Boolean,
      required: true,
    },
    showFirstLastIcon: {
      type: Boolean,
      default: true,
    },
    singleSelect: {
      type: Boolean,
      default: true,
    },
    showSelect: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    options: {
      handler() {
        const {
          multiSort,
          sortBy,
          sortDesc,
          page,
          itemsPerPage,
        } = this.options;

        if (!multiSort) {
          this.searchParams = {
            page,
            rows: itemsPerPage,
            sidx: sortBy[0],
            sord:
              sortDesc[0] === true
                ? 'desc'
                : sortDesc[0] === false
                ? 'asc'
                : null,
          };
        }
        // 검색 항목 병합
        const reqBody = this.searchParams;
        Object.assign(reqBody, this.searchOpts);
        // 검색
        this.$emit('getDataFromApi', reqBody);
      },
      deep: true,
    },
    reloadToggle() {
      if (this.reloadToggle != undefined) {
        this.search();
      }
    },
  },
  data: () => ({
    pageOptions: [10, 20, 30, 40, 50, 100],
    options: {},
    // loading: true,
    selected: [],
  }),
  computed: {
    // itemWith Index
    tableItems() {
      let idx =
        this.options.page > 1
          ? (this.options.page - 1) * this.options.itemsPerPage + 1
          : 1;
      // return this.items.map(tableItem => ({
      //   ...tableItem,
      //   index: idx++,
      // }));

      // 빈칸 '-' 표시 (null & undefined & empty)
      const tableList = this.items;
      tableList.forEach(item => {
        Object.keys(item).forEach(key => {
          item[key] = item[key] || (item[key] === 0 ? 0 : '-');
        });
        item.index = idx++;
      });
      return tableList;
    },
    // headerWithIndex
    tableHeaders() {
      if (this.singleSelect === true) {
        return [
          {
            value: 'index',
            align: 'center',
            sortable: false,
            width: '4%',
          },
          ...[
            ...this.headers.map(item => ({
              ...item,
              align: 'center',
            })),
          ],
        ];
      } else {
        return [
          {
            value: 'data-table-select',
            align: 'left',
            sortable: false,
            width: '4%',
          },
          ...[
            ...this.headers.map(item => ({
              ...item,
              align: 'center',
            })),
          ],
        ];
      }
    },
    afterIconMarginLeft() {
      if (this.showFirstLastIcon) {
        return {
          '--after-icon-margin-left': '154px',
        };
      } else {
        return {
          '--after-icon-margin-left': '220px',
        };
      }
    },
    btnSearch() {
      return this.$i18n.t('common.btn.search');
    },
  },
  methods: {
    // row 선택
    rowClick(item, row) {
      // click row 색상 변경
      if (row.isSelected) row.select(false);
      else row.select(true);
      // 부모컴포넌트의 rowClick 라는 이벤트로 데이터를 담기
      this.$emit('rowClick', item);
    },
    search() {
      this.select = 0;
      const defaultOptions = {
        page: 1,
        rows: this.nowPerPage,
        sidx: null,
        sord: 'asc',
      };
      // seatchOpts.pageId 있으면 초기화
      if (this.searchOpts.pageId) {
        this.searchOpts.pageId = '';
      }

      if (
        this.options.page == defaultOptions.page &&
        this.options.itemsPerPage == defaultOptions.rows &&
        this.options.sortBy == defaultOptions.sidx
      ) {
        const reqBody = defaultOptions;

        Object.assign(reqBody, this.searchOpts);
        this.$emit('getDataFromApi', reqBody);
      } else {
        const {
          multiSort,
          sortBy,
          sortDesc,
          page,
          itemsPerPage,
        } = this.options;
        if (!multiSort) {
          this.searchParams = {
            page,
            rows: itemsPerPage,
            sidx: sortBy[0],
            sord:
              sortDesc[0] === true
                ? 'desc'
                : sortDesc[0] === false
                ? 'asc'
                : null,
          };
        }
        // 검색 항목 병합
        const reqBody = this.searchParams;

        Object.assign(reqBody, this.searchOpts);
        // 검색
        this.$emit('getDataFromApi', reqBody);
        this.options.page = 1;
      }

      // 선택 컬럼 clear
      this.clearSelect();
    },
    // paging Input 확인
    checkInput(e) {
      const value = e.target.value;
      if (value > 0 && value <= this.totalPages) {
        this.options.page = Number(value);
      }
    },
    enterSelect() {
      let selectedArray = this.selected.map(e => e.trnscSn);
      this.$store.commit('transactionlog/setTrnscSns', selectedArray);
    },
    clearSelect() {
      this.selected = [];
    },
  },
};
</script>
<style scoped>
::v-deep .v-data-footer__icons-after {
  margin-left: var(--after-icon-margin-left);
}
</style>
