<template>
  <v-app app id="bodyDiv" style="visibility: inherit">
    <div>
      <!-- TODO: 세션 체크 실패할 경우, 화면 안보이도록 처리 -->
      <Header />
      <div class="mdm-main-wrapper">
        <div class="mdm-main-wrap">
          <router-view />
        </div>
      </div>
    </div>
  </v-app>
</template>

<script>
import Header from '@/layouts/Header.vue';

export default {
  components: {
    Header,
  },
};
</script>

<style lang="scss">
@import '@/styles/variables.scss';
</style>
